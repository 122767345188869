<template>
    <div class="monthly-closing-container">
        <div class="column">

            <div class="item" v-for="(item, index) in workTimeItems" v-show="item.show" :key="'wt-'+index" v-bind:style="{flex: '1 1 calc(' + (100 / columns) + '% - 5px)', maxWidth: 'calc(' + (100 / columns) + '% - 5px)'}">
                <labelc :text="item.label" :styleText="item.styleText ? item.styleText : null" :components="item.components ? item.components: null"  />
                <labelc :text="(employeeData ? (item.displayLabel(employeeData[item.key])) : 0).toFixed(2)" :styleText="item.styleText ? item.styleText : null" />
            </div>

        </div>
        <div class="column">

            <div class="item" v-for="(item, index) in vacationItems" :key="'vt-'+index" v-bind:style="{flex: '1 1 calc(' + (100 / columns) + '% - 5px)', maxWidth: 'calc(' + (100 / columns) + '% - 5px)'}">
                <labelc :text="item.label"/>
                <labelc :text="(employeeData ? (item.displayLabel(employeeData[item.key])) : 0).toFixed(2)"/>
            </div>
        </div>

        <div class="column">

            <div class="item" v-for="(item, index) in holidayItems" :key="'ht-'+index" v-bind:style="{flex: '1 1 calc(' + (100 / columns) + '% - 5px)', maxWidth: 'calc(' + (100 / columns) + '% - 5px)'}">
                <labelc :text="item.label"/>
                <labelc :text="(employeeData ? (item.displayLabel(employeeData[item.key])) : 0).toFixed(2)"/>
            </div>
        </div>
        <div class="column">

            <div class="item" v-for="(item, index) in nightWorkItems" :key="'nt-'+index" v-bind:style="{flex: '1 1 calc(' + (100 / columns) + '% - 5px)', maxWidth: 'calc(' + (100 / columns) + '% - 5px)'}">
                <labelc :text="item.label"/>
                <labelc :text="(employeeData ? (item.displayLabel(employeeData[item.key])) : 0).toFixed(2)"/>
            </div>
        </div>
        <div class="column">

            <div class="item" v-for="(item, index) in overTimeItems" :key="'ot-'+index" v-bind:style="{flex: '1 1 calc(' + (100 / columns) + '% - 5px)', maxWidth: 'calc(' + (100 / columns) + '% - 5px)'}">
                <labelc :text="item.label"/>
                <labelc :text="(employeeData ? (item.displayLabel(employeeData[item.key])) : 0).toFixed(2)"/>
            </div>
        </div>



    </div>
</template>

<script>
    export default {
        name: "monthlyClosingSummary",
        data(){
            return {
                columns: 1,
                showActualWorkTimeBreakdown: false,
                showUnproductiveWorkTimeBreakdown: false,
            }
        },
        props:{
            employeeData: Object,
            prevMonthWorkBalance: Object,
            enableWorkBalanceMultiplier: Boolean,
            optionalColumn: Boolean,
        },
        computed: {
            workTimeItems() {
                let view = this;
                let showActualItems = view.showActualWorkTimeBreakdown;
                let showUnproductiveItems = view.showActualWorkTimeBreakdown && view.showUnproductiveWorkTimeBreakdown;
                let items = [

                    {label: "SOLL", value: 0, key: 'workTimeRegular', displayLabel: (val) => {return (val/60.0)}, show: true},
                    {label: "IST", value: 0, key: 'totalWorkTime',
                    displayLabel: (val) => {
                        return (view.employeeData.prevMonthTime + view.employeeData.workTime + view.employeeData.unproductiveWorkTime)/ 60;
                    },
                    show: true,
                    styleText: view.showActualWorkTimeBreakdown ? {color: "var(--contrast-6)"} : null,
                    components: [
                        {
                        type: 'buttonc',
                        propContainer: {type: 'button icon-overlay square', icon: 'fa-info-circle'},
                        customEvents:{ click: (event) => {
                            view.showActualWorkTimeBreakdown = !view.showActualWorkTimeBreakdown;
                        }},
                        }
                    ]},
                    {label: "Übertrag Vormonat", value: 0, key: 'prevMonthTime', displayLabel: (val) => {return (val/60.0)}, show: showActualItems},
                    {label: "Produktive Arbeitszeit", value: 0, key: 'workTime', displayLabel: (val) => {return (val/60.0)}, show: showActualItems},
                    {label: "Unproduktive Arbeitszeit", value: 0, key: 'unproductiveWorkTime', displayLabel: (val) => {return (val/60.0)},
                    show: showActualItems,
                    styleText: showUnproductiveItems ? {color: "var(--contrast-6)"} : null,
                    components: [
                        {
                        type: 'buttonc',
                        propContainer: {type: 'button icon-overlay square', icon: 'fa-info-circle'},
                        customEvents:{ click: (event) => {
                            view.showUnproductiveWorkTimeBreakdown = !view.showUnproductiveWorkTimeBreakdown;
                        }},
                        }
                    ]},

                    {label: "Krankenstand", value: 0, key: 'sickWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Lohnfortzahlung", value: 0, key: 'wageContinuationWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Ausbildung", value: 0, key: 'educationWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Pflegeurlaub", value: 0, key: 'nursingVacationWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Urlaub", value: 0, key: 'vacationWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Sonstige Abwesenheiten", value: 0, key: 'miscWorkTime', displayLabel: (val) => {return (val/60.0)}, show: showUnproductiveItems},
                    {label: "Modifizierte Arbeitszeit", value: 0, key: 'paidWorkTime', displayLabel: (val) => {return (val/60.0)}, show: true},



                ];
                if(this.enableWorkBalanceMultiplier){
                    items = items.concat(
                        [
                            {label: "Multiplikator", value: 0, key: 'multiplier', displayLabel: (val) => {return (val/100.0)}, show: true},
                            {label: "Durchrechnungs-Übertrag", value: 0, key: 'accountingPeriodBalance', displayLabel: (val) => {return (val/60.0)}, show: true},

                        ]
                    )
                }
                return items;
            },
            vacationItems(){
                let view = this;
                let items = [
                   {label: "Urlaubsstand Vormonat", value: 0, key: 'prevMonthVacation', displayLabel: (val) => {
                        let prevMonthVacation = view.prevMonthWorkBalance ? view.prevMonthWorkBalance.vacationTimeBalance : 0;

                        return (prevMonthVacation/60.0/24)
                    }},
                    {label: "Urlaubs Zuwachs", value: 0, key: 'contractVacation', displayLabel: (val) => {
                        let prevMonthVacation = view.prevMonthWorkBalance ? view.prevMonthWorkBalance.vacationTimeBalance : 0;

                        return (view.employeeData.vacationTimeBalance - prevMonthVacation + view.employeeData.vacationTime)/60.0/24;
                    }},
                    {label: "Urlaub (Tage)", value: 0, key: 'vacationTime', displayLabel: (val) => {return (val/60.0/24)}},
                    {label: "Modifizierter Urlaub", value: 0, key: 'paidVacationTime', displayLabel: (val) => {return (val/60.0/24)}},



                ];
                return items;
            },
            holidayItems() {
                let view = this;
                let items = [
                    {label: "Feiertagsarbeit Vormonat", value: 0, key: 'prevMonthHolidayTime', displayLabel: (val) => {
                        let prevMonthHoliday = view.prevMonthWorkBalance ? view.prevMonthWorkBalance.holidayTimeBalance : 0;

                        return (prevMonthHoliday/60.0)
                    }},
                    {label: "Feiertagsarbeit", value: 0, key: 'holidayTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Feiertags Ausgleich", value: 0, key: 'holidayAdjustmentWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Modifizierte Feiertage", value: 0, key: 'paidHolidayTime', displayLabel: (val) => {return (val/60.0)}},



                ];
                return items;
            },
            nightWorkItems(){
                let view = this;
                let items = [
                    {label: "Nachtarbeit Vormonat", value: 0, key: 'prevMonthNightWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Nachtarbeit", value: 0, key: 'nightWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Nachtarbeit Anzahl", value: 0, key: 'nightWorkCount', displayLabel: (val) => {return (val)}},
                    {label: "Modifizierte Nachtarbeit", value: 0, key: 'paidNightWorkTime', displayLabel: (val) => {return (val/60.0)}},



                ];
                return items;
            },
            overTimeItems() {
                let view = this;
                let items = [
                    {label: "Überstunden Vormonat", value: 0, key: 'prevMonthOverTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Überstunden", value: 0, key: 'overTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Überstunden Anzahl", value: 0, key: 'overTimeCount', displayLabel: (val) => {return (val)}},
                    {label: "Modifizierte Überstunden", value: 0, key: 'paidOverTime', displayLabel: (val) => {return (val/60.0)}},
                ];
                return items;
            },
            items(){
                let view = this;
                let items = [
                    {label: "Übertrag Vormonat", value: 0, key: 'prevMonthTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "SOLL", value: 0, key: 'workTimeRegular', displayLabel: (val) => {return (val/60.0)}},
                    {label: "IST", value: 0, key: 'totalWorkTime', displayLabel: (val) => {
                        return (view.employeeData.prevMonthTime + view.employeeData.workTime + view.employeeData.unproductiveWorkTime)/ 60;
                    }},
                    {label: "Produktive Arbeitszeit", value: 0, key: 'workTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Unproduktive Arbeitszeit", value: 0, key: 'unproductiveWorkTime', displayLabel: (val) => {return (val/60.0)}},

                    {label: "Krankenstand", value: 0, key: 'sickWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Lohnfortzahlung", value: 0, key: 'wageContinuationWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Ausbildung", value: 0, key: 'educationWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Pflegeurlaub", value: 0, key: 'nursingVacationWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Urlaub", value: 0, key: 'vacationWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Sonstige Abwesenheiten", value: 0, key: 'miscWorkTime', displayLabel: (val) => {return (val/60.0)}},
                    {label: "Modifizierte Arbeitszeit", value: 0, key: 'paidWorkTime', displayLabel: (val) => {return (val/60.0)}},



                ];
                if(this.enableWorkBalanceMultiplier){
                    items = items.concat(
                        [
                            {label: "Durchrechnungs-Übertrag", value: 0, key: 'accountingPeriodBalance', displayLabel: (val) => {return (val/60.0)}},
                            {label: "Multiplikator", value: 0, key: 'multiplier', displayLabel: (val) => {return (val/100.0)}},
                        ]
                    )
                }
                items = items.concat(
                    [
                        {label: "Urlaubsstand Vormonat", value: 0, key: 'prevMonthVacation', displayLabel: (val) => {
                            let prevMonthVacation = view.prevMonthWorkBalance ? view.prevMonthWorkBalance.vacationTimeBalance : 0;

                            return (prevMonthVacation/60.0/24)
                        }},
                        {label: "Urlaubs Zuwachs", value: 0, key: 'contractVacation', displayLabel: (val) => {
                            let prevMonthVacation = view.prevMonthWorkBalance ? view.prevMonthWorkBalance.vacationTimeBalance : 0;

                            return (view.employeeData.vacationTimeBalance - prevMonthVacation + view.employeeData.vacationTime)/60.0/24;
                        }},
                        {label: "Urlaub (Tage)", value: 0, key: 'vacationTime', displayLabel: (val) => {return (val/60.0/24)}},
                        {label: "Modifizierter Urlaub", value: 0, key: 'paidVacationTime', displayLabel: (val) => {return (val/60.0/24)}},


                        {label: "Feiertags Arbeitszeit", value: 0, key: 'holidayTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Feiertags Ausgleich", value: 0, key: 'holidayAdjustmentWorkTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Modifizierte Feiertage", value: 0, key: 'paidHolidayTime', displayLabel: (val) => {return (val/60.0)}},


                        {label: "Nachtarbeit Vormonat", value: 0, key: 'prevMonthNightWorkTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Nachtarbeit", value: 0, key: 'nightWorkTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Nachtarbeit Anzahl", value: 0, key: 'nightWorkCount', displayLabel: (val) => {return (val)}},
                        {label: "Modifizierte Nachtarbeit", value: 0, key: 'paidNightWorkTime', displayLabel: (val) => {return (val/60.0)}},


                        {label: "Überstunden Vormonat", value: 0, key: 'prevMonthOverTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Überstunden", value: 0, key: 'overTime', displayLabel: (val) => {return (val/60.0)}},
                        {label: "Überstunden Anzahl", value: 0, key: 'overTimeCount', displayLabel: (val) => {return (val)}},
                        {label: "Modifizierte Überstunden", value: 0, key: 'paidOverTime', displayLabel: (val) => {return (val/60.0)}},
                    ]
                )
                return items;
            }
        }
    }
</script>

<style scoped>
.monthly-closing-container{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    background: #ec5580;
    display: flex;
    flex-wrap: wrap
}

.column {
  flex: 1 1 auto;
  width: 15%;
}
.small-column {
    flex: 1 1 auto;
    width: 10%;
}

.item{

    height: 40px;
    margin: 2.5px 2.5px;
    background: #fad0dc;
    position: relative;
    display: flex;
    flex-grow: unset;
}

.item .label:first-child{
    position: absolute;;
    left: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.item .label:last-child{
    position: absolute;;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-weight: 200;
    font-family: DistrictProThin;
}


</style>
